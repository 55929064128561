function FifthSection() {
    return (
        <div className="FifthSection">
            {/* <img src={quote} id="quote" alt="title"/>
            <img src={quote_r} id="quoter" alt="title"/> */}
            <h1>First Launch</h1>
            <p>Join us on our journey! Interstellar Orbits’ inaugural launch involves testing the model of the rocket that will be delivering future 3D printed payloads. Partnering with the University of Colorado Boulder Sounding Rocket Laboratory (SRL) for the SpaceShot project, we support the next generation of aerospace students. Witness history as the Kármán line is surpassed with SpaceShot’s projected 110 km peak reaching into the atmosphere, breaking the collegiate record for altitude.</p>
        </div>
    );
}

export default FifthSection;

