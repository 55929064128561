import quote from "./weather_balloon_1.png"

function SixthSection() {
    return (
        <div className="SixthSection">
            <img src={quote} id="quote" alt="title"/>
            
            <h1>Groundbreaking Collegiate Payload</h1>
            <p>After project SpaceShot, Interstellar Orbits will again be collaborating with the SRL in order to deliver the first collegiate payload into orbit. The rocket will deploy our 3D printed weather balloon, designed to record essential geospatial data such as atmospheric pressure, ozone, satellite imagery, precipitation, cloud cover and oceanography.</p>
        </div>
    );
}

export default SixthSection;