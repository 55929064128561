import SixthSection from "./SixthSection";
import FifthSection from "./FifthSection";
import FourthSection from "./FourthSection";
import SecondSection from "./SecondSection";
import SplashScreen from "./SplashScreen";
import ThirdSection from "./ThirdSection";

function Body({globalState, setGlobalState}) {
  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    let width = 100;
    if(scrollTop > 300) {
      width = 100 - Math.floor((scrollTop - 300) / 20);
    }
    if(width < 80) width = 80;
    if(width > 100) width = 100;
    if(globalState.headerSize !== width) {
      setGlobalState((current) => { return {...current, headerSize: width}});
      // console.log(width);
    }
  };

  const otherClickHandler = (e) => {
    e.stopPropagation();
    setGlobalState((current) => {return {...current, navInUse: false}})
  }


  return (
    <div className="App-body" onClick={otherClickHandler} onScroll={handleScroll} style={globalState.navInUse? {overflowY: "hidden"} : null}>
      <SplashScreen />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
    </div>
  );
}
  
  export default Body;

