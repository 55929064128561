import sat_img from "./earth_rotating.mp4"  

function SplashScreen() {
    return (
        <div className="ParallexSection" id="fsection">
            <div id="splashtext">
                <div id="title-text" title="INTERSTELLAR ORBITS">INTERSTELLAR ORBITS</div>
                {/* <p className="SplashScrnText">Revolutionize Geospatial Data Collection</p> */}
            </div>
            <video src={sat_img} className="StaticImage" alt="Satellite" muted autoPlay loop playsInline />
        </div>
    );
  }
  
  export default SplashScreen;