import sat_view from "./printing.mp4"
import wb_img from "./print.png"

function FourthSection() {
    return (
        <div className="ParallexSection" id="FourthSection">
            <video src={sat_view} className="StaticImage" alt="Satellite" autoPlay muted playsInline loop/>
            <div className="BalloonImgDiv">
                <img src={wb_img}  alt="title" />
            </div>
            <div className="textSection">
                <h1>3D Printing</h1>
                <p>The space industry suffers from costly and inefficient payloads that litter the planet. Our sustainable manufacturing process utilizes state-of-the-art 3D printing facilitates and technologies to maximize production, enabling more launches at a lower cost. Furthermore, 3D printing minimizes industry waste by streamlining assembly, reducing unnecessary material use, and contributing to a cleaner planet.</p>
            </div>
        </div>
    );
}

export default FourthSection;