import { useEffect, useRef, useState } from "react";
import laptop_img from "./logo.png"


function SecondSection() {
    const [visible, setVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const obs = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);
        },
        {rootMargin: "-150px"});
        obs.observe(sectionRef.current);
        return () => {obs.disconnect()}
    }, []);

    return (
        <div className="SecondSection" ref={sectionRef}>
            <img id="laptop-img" src={laptop_img} alt="Laptop" className={visible? "show":"left-hidden"}/>
            <div id="section2-text">
                <h1 id="second-h1" className={visible? "show":"right-hidden"}>MISSION STATEMENT</h1>
                <h2 id="second-h2" className={visible? "show":"right-hidden"}>To revolutionize geospatial data collection by using 3D printing to manufacture cutting-edge payloads for deployment into the atmosphere.</h2>
            </div>
            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>
        </div>
    );
}

export default SecondSection;