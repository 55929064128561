import './App.css';
import Body from './Body';
import { useState } from 'react';


function App() {
  const [globalState, setGlobalState] = useState({
    headerSize: 100,
    navInUse: false
  });

  return (
    <div className="App"> 
      <Body globalState={globalState} setGlobalState={setGlobalState}/>
    </div>
  );
}

export default App;
