import laptop_img from "./laptop_im.png";
import data_vid from "./data_vid.mp4";
import { useEffect, useRef, useState } from "react";

function ThirdSection() {
    const [visible, setVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const obs = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);
        },
        {rootMargin: "-150px"});
        obs.observe(sectionRef.current);
        return () => {obs.disconnect()}
    }, []);

    return (
        <div ref={sectionRef} className="ThirdSection">
            <div className={visible? "show":"right-hidden"} id="video-in-laptop">
                <img src={laptop_img} alt="laptop"/>
                <video src={data_vid} autoPlay muted loop playsInline />
            </div>
            <div>
                <h1 className={visible? "show":"left-hidden"}>Vision</h1>
                <p className={visible? "show":"left-hidden"}>At Interstellar Orbits, we envision a future where our advanced 3D printed payloads redefine space exploration and Earth development. Pioneering 3D printed micro-satellites, and incorporating innovative weather balloon technology, we empower diverse sectors, from NGOs to governments, agriculture to construction, with geospatial data that benefits humankind.</p>
            </div>
        </div>
    );
}

export default ThirdSection;